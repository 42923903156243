import React from 'react';
import './Main.css';
import Logo from './images/townies-logo.png';
import { GiHamburgerMenu } from 'react-icons/gi';
import { IoLocationSharp, IoCallSharp } from 'react-icons/io5';
import { BsDoorOpenFill } from 'react-icons/bs';
import { SiUbereats } from 'react-icons/si';
import StreetImage from './images/street-image.png';

function Main() {

    return (
        <React.Fragment>
            <div className="main">
                <div className="navBar">
                    <GiHamburgerMenu className="side-menu" size={40} color={"white"} />
                    <img src={Logo} className="logo" alt="logo"></img>
                    <div className="navBar-links">
                        <p className="navBar-page" style={{ color: "black" }}>Home</p>
                        <p className="navBar-page">Menu</p>
                        <p className="navBar-page">About Us</p>
                        <p className="navBar-page">Online Ordering</p>
                        <p className="navBar-page">Services</p>
                        <p className="navBar-page">Testimonials</p>
                        <p className="navBar-page">Contact Us</p>
                    </div>
                </div>
                <div className="main-div">
                    <div className="main-div-left">
                        <img id="street-image" src={StreetImage} alt="townies-street"></img>
                    </div>
                    <div className="main-div-right">
                        <p id="title">TOWNIES FEEL GOOD FOOD</p>
                        <p id="subtitle">Come in for fresh food and a friendly atmosphere that will make you feel right at home!</p>
                        <div className="icon-text" style={{ cursor: "pointer" }}>
                            <IoLocationSharp id="icon" color={"black"} />
                            <p id="text">324 Waterman Ave. East Providence, RI. 02914</p>
                        </div>
                        <div className="icon-text" style={{ cursor: "pointer" }}>
                            <IoCallSharp id="icon" color={"black"} />
                            <p id="text">401-473-0011</p>
                        </div>
                        <div className="icon-text">
                            <BsDoorOpenFill id="icon" color={"black"} />
                            <p id="text">Mon-Sat: 11:00am - 9:00pm</p>
                        </div>
                        <div className="bottom-buttons">
                            <div id="button-one" >Order Online</div>
                            <div id="button-two"><SiUbereats id="button-icon" color={"white"} /></div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Main;